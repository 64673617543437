import React from "react"
import Layout from "../components/Layout"
import "../css/projects/web-project/webprojectcard.css"
import { Link } from "gatsby"
import Particle6 from "../components/Particle6"
import SEO from "../components/SEO"

const Webprojectcard = () => {
  return (
    <div>
      <Layout>
        <SEO
          title="Web-dev Projects"
          description="This Page is for Web-dev cool projects ."
        />
        <Particle6></Particle6>
        <h6>s</h6>
        <div className="programming-project">
          <h1>Web Dev Projects</h1>
        </div>

        <div className="fav noSelect">
          <h3>
            Those projects having a{" "}
            <span role="img" aria-label="Heart">
              ❤️
            </span>{" "}
            on them...come under the Favourite category.{" "}
          </h3>
        </div>

        <section className="most-liked noSelect">
          <h3 className="total">Total Projects :- 6</h3>
          <div className="row">
            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">
                    Ristotante Confusion
                    <span role="img" aria-label="Heart">
                      ❤️
                    </span>
                  </h3>
                </div>
                <div className="card-img1"></div>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/conFusion-React-Firebase"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>

                      <a
                        href="https://ristorante-con-fusion-food.netlify.app/"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        View Live
                      </a>

                      <Link to="/web_proj1/" className="btntt">
                        More Detail
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">
                    Coffee Shop
                    <span role="img" aria-label="Heart">
                      ❤️
                    </span>
                  </h3>
                </div>

                <div className="card-img3"></div>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Coffee-Website-Bootstrap"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                      <a
                        href="https://arpitsomani8.github.io/Coffee-Website-Bootstrap/"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        View Live
                      </a>
                      <Link to="/web_proj3/" className="btntt">
                        More Detail
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">Business Website</h3>
                </div>

                <div className="card-img4"></div>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Business-Website-Bootstrap"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                      <a
                        href="https://arpitsomani8.github.io/Business-Website-Bootstrap/"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        View Live
                      </a>
                      <Link to="/web_proj4/" className="btntt">
                        More Detail
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">Men's Cloth Store</h3>
                </div>

                <div className="card-img2"></div>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Men-Cloth-Website-Bootstrap"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                      <a
                        href="https://arpitsomani8.github.io/Men-Cloth-Website-Bootstrap/"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        View Live
                      </a>
                      <Link to="/web_proj2/" className="btntt">
                        More Detail
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">Quiz Application</h3>
                </div>

                <div className="card-img5"></div>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/JavaScript-Quiz"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                      <a
                        href="https://arpitsomani8.github.io/JavaScript-Quiz/"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        View Live
                      </a>
                      <Link to="/web_proj5/" className="btntt">
                        More Detail
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">Calculator</h3>
                </div>

                <div className="card-img6"></div>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/JavaScript-Calculator"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                      <a
                        href="https://arpitsomani8.github.io/JavaScript-Calculator/"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        View Live
                      </a>
                      <Link to="/web_proj6/" className="btntt">
                        More Detail
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </div>
  )
}

export default Webprojectcard
